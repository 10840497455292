<template>
	<div>
		<navbar />
		<div class="flex flex-grow">
			<sidebar/>
			<div class="flex flex-col flex-grow flex-shrink min-h-screen" style="padding-top: 2.8rem;">
				<div class="pt-1 pb-14">
					<transition name="fade" mode="out-in">
						<router-view/>
					</transition>
				</div>
				<div class="bg-gray-900 px-5 py-3 z-20 border-t border-gray-700 text-gray-400 bottom-0 fixed w-full">
					© 2023 <a href="https://ndpteknologi.com" target="_blank">NDP TEKNOLOGI</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import navbar from './components/navbar.vue'
import sidebar from './components/sidebar.vue'
export default {
	components: {navbar, sidebar},
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
transition-duration: 0.2s;
transition-property: opacity;
transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
opacity: 0
}
</style>